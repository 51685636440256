export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const VALIDATE_SUCCESS = "VALIDATE_SUCCESS";
export const VALIDATE_FAIL = "VALIDATE_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const SEND_EMAIL_PASSWORD_RECOVERY_SUCCESS =
  "SEND_EMAIL_PASSWORD_RECOVERY_SUCCESS";
export const SEND_EMAIL_PASSWORD_RECOVERY_FAIL =
  "SEND_EMAIL_PASSWORD_RECOVERY_FAIL";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAIL = "PASSWORD_RECOVERY_FAIL";
export const LOGOUT = "LOGOUT";
export const VALIDATE_LOCAL_TOKEN = "VALIDATE_LOCAL_TOKEN";
export const VALIDATE_LOCAL_TOKEN_FAIL = "VALIDATE_LOCAL_TOKEN_FAIL";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const GET_MEMBER_PROFILE = "GET_MEMBER_PROFILE";
export const GET_MEMBER_PROFILE_FAIL = "GET_MEMBER_PROFILE_FAIL";
export const UPDATE_MEMBER_PROFILE = "UPDATE_MEMBER_PROFILE";
export const CREATE_MEMBER_PROFILE = "CREATE_MEMBER_PROFILE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_FAIL = "ADD_FAIL";
export const ADD_MEMBER_TO_CART = "ADD_MEMBER_TO_CART";
export const ADD_MEMBER_FAIL = "ADD_MEMBER_FAIL";

export const SUBS_TO_CART = "SUBS_TO_CART";
export const SUBS_FAIL = "SUBS_FAIL";
export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";
export const CHECKOUT_PAYMENT = "CHECKOUT_PAYMENT";
export const CHECKOUT_PAYMENT_FAIL = "CHECKOUT_PAYMENT_FAIL";
export const GET_CART = "GET_CART";
export const GET_CART_FAIL = "GET_CART_FAIL";
export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_FAIL = "DELETE_CART_FAIL";
export const DESTROY_CART = "DESTROY_CART";
export const DESTROY_CART_FAIL = "DESTROY_CART_FAIL";
export const DISCOUNT_CODE_APPLIED = "DISCOUNT_CODE_APPLIED";
export const DISCOUNT_CODE_FAIL = "DISCOUNT_CODE_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GUEST_USER = "GUEST_USER";
export const LOGGED_USER = "LOGGED_USER";
export const MEMBER_USER = "MEMBER_USER";

export const GET_ALL_SUPPORT_SUCCESS = "GET_ALL_SUPPORT_SUCCESS";
export const GET_ALL_SUPPORT_FAIL = "GET_ALL_SUPPORT_FAIL";
export const GET_ALL_AGENDA_SUCCESS = "GET_ALL_AGENDA_SUCCESS";
export const GET_ALL_AGENDA_FAIL = "GET_ALL_AGENDA_FAIL";
export const GET_ALL_BOTIGA_SUCCESS = "GET_ALL_BOTIGA_SUCCESS";
export const GET_ALL_BOTIGA_FAIL = "GET_ALL_BOTIGA_FAIL";
export const GET_ALL_MEMBERSHIPS_SUCCESS = "GET_ALL_MEMBERSHIPS_SUCCESS";
export const GET_ALL_MEMBERSHIPS_FAIL = "GET_ALL_MEMBERSHIPS_FAIL";
export const GET_ABOUT = "GET_ABOUT";
export const GET_COVER = "GET_COVER";
export const GET_COLLABORATORS = "GET_COLLABORATORS";
export const GET_MEMBER_PROJECTS = "GET_MEMBER_PROJECTS";
export const STORE_UPLOADED_IMAGES = "STORE_UPLOADED_IMAGES";

export const OPEN_FULLSCREEN = "OPEN_FULLSCREEN";
export const CLOSE_FULLSCREEN = "CLOSE_FULLSCREEN";

export const OPEN_SITE_UNAVAILABLE = "OPEN_SITE_UNAVAILABLE";
export const CLOSE_SITE_UNAVAILABLE = "CLOSE_SITE_UNAVAILABLE";

// loaders
export const LOADING_MANIFESTO = "LOADING_MANIFESTO";
export const LOADING_ARTISTS = "LOADING_ARTISTS";
export const LOADING_EVENTS = "LOADING_EVENTS";
export const LOADING_SUBSCRIPTIONS = "LOADING_SUBSCRIPTIONS";
export const LOADING_MEMBER_PROJECTS = "LOADING_MEMBER_PROJECTS";
export const LOADING_ABOUT = "LOADING_ABOUT";
export const LOADING_COVERS = "LOADING_COVERS";
export const LOADING_ARTICLES = "LOADING_ARTICLES";
export const LOADING_GALERIA = "LOADING_GALERIA";

export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const OPEN_CART_MENU = "OPEN_CART_MENU";
export const CLOSE_CART_MENU = "CLOSE_CART_MENU";
export const OPEN_PROFILE_MENU = "OPEN_PROFILE_MENU";
export const CLOSE_PROFILE_MENU = "CLOSE_PROFILE_MENU";
